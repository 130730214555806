import React from 'react'
import ReactDOM from 'react-dom'

document.addEventListener('turbo:load', () => {
  const COMPONENTS = import.meta.glob('@components/**/*.jsx', { import: 'default', eager: true });
  // console.log('COMPONENTS', COMPONENTS)

  // Get all tags generated by the `rails_component` helper.
  const mountingElements = document.querySelectorAll('[data-react-component]')

  for (const element of mountingElements) {
    // Extract name and props from the data present in the element
    const { name, props } = JSON.parse(element.getAttribute('data-react-component'))

    // Retrieve the component from the `components` object we mounted before,
    // and check if it exists.
    const component = COMPONENTS[`/javascript/app/components/${name}/index.jsx`] || COMPONENTS[`/javascript/app/components/${name}.jsx`]
    if (!component) {
      console.log(`Component ${name} not found`)
      continue
    }

    // Mount the component with the props inside the element
    ReactDOM.render(
      React.createElement(component, props || {}),
      element
    )
  }
})


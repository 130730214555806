import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  display: inline-block;
  margin-right: 6px;
  border: 3px solid #ccc; 
  border-top: 3px solid #9360FF;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 1s linear infinite;
`

export const LoadingMessage = styled.div`
  color: #FF6F45;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
`
import React, { Component } from "react";
import Select, { components } from 'react-select'

class FRGBigSelect extends Component {

  render() {

    const DownArrow = () => {
      return <i className="icon icon--arrow--down--white" />;
    };

    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <DownArrow />
          </components.DropdownIndicator>
        )
      );
    };

    const customStyles = {
      container: (base) => ({
        ...base,
        flexGrow: 1,
      }),
      valueContainer: base => ({
        padding: 0
      }),
      control: (base, state) => ({
        ...base,
        width: '100%',
        border: state.isFocused ? 0 : 0,
        boxShadow: state.isFocused ? 0 : 0,
        fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
        fontSize: 14,
        height: 55,
        backgroundColor: "#9360FF",
        color: "#FFF",
        padding: "0 24px"
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none'
      }),
      indicatorsContainer: base => ({
        ...base,
      }),
      menu: base => ({
        ...base,
        border: 0,
        boxShadow: '0 4px 11px hsla(0, 0%, 0%, 0.1)'
      }),
      menuList: base => ({
        paddingTop: 0,
        paddingBottom: 0,
      }),
      singleValue: base => ({
        ...base,
        fontSize: 14,
        fontWeight: 500,
        color: "#FFF",
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#FFF' : state.isFocused ? '#3B3B3B' : '#3B3B3B',
        backgroundColor: state.isSelected ? '#9360FF' : state.isFocused ? 'rgba(0, 188, 144, 0.15)' : '#FFF',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: 14,
        ':active': {
          backgroundColor: 'rgba(0, 188, 144, 0.15)'
        },
        padding: "16px 24px"
      })
    }

    return (
      <Select
        styles={customStyles}
        isSearchable={false}
        components={{ DropdownIndicator }}
        options={this.props.options}
        value={this.props.value}
        onChange={this.props.onChange}
        classNamePrefix="frg"
      />
    )
  }
}

export default FRGBigSelect;

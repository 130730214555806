import { Pointer } from "highcharts";
import { camelCase } from "lodash";
import React, { Component } from "react";
import Select, { components } from 'react-select'
import { Modal } from '@styles/modal'

class ReportBuilderSelect extends Component {

  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  render() {

    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <i className="icon icon--arrow--down--green" />
          </components.DropdownIndicator>
        )
      );
    };

    const customStyles = {
      container: (base) => ({
        ...base,
        flexGrow: 1,
      }),
      valueContainer: base => ({
        padding: 0
      }),
      control: (base, state) => ({
        ...base,
        width: '100%',
        border: state.isFocused ? 0 : 0,
        boxShadow: state.isFocused ? 0 : 0,
        fontFamily: "'Helvetica Neue', Helvetica, sans-serif",
        fontSize: 13,
        backgroundColor: "#EFEFEF",
        color: "rgba(0,0,0,0.3)",
        padding: "0px",
        cursor: 'pointer',
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none'
      }),
      indicatorsContainer: base => ({
        ...base,
      }),
      menu: base => ({
        ...base,
        border: 0,
        borderRadius: 10,
        marginTop: 30,
        paddingTop: 7,
        paddingBottom: 7,
        marginLeft: "-24px",
        marginRight: "-24px",
        width: "calc(100% + 48px)",
        boxShadow: '0 4px 11px hsla(0, 0%, 0%, 0.1)',
        zIndex: 20,
      }),
      menuList: base => ({
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: "50vh",
        overflow: "auto",
        "::-webkit-scrollbar": {
          width: "10px;"
        },
        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#777",
          borderRadius: "5px"
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#777"
        }
      }),
      singleValue: base => ({
        ...base,
        fontSize: 13,
        color: "#000",
        fontFamily: 'Helvetica, sans-serif',
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#000' : state.isFocused ? '#000' : '#434B58',
        backgroundColor: "#fff", //state.isSelected ? '#9360FF' : state.isFocused ? 'rgba(0, 188, 144, 0.15)' : '#FFF',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: 13, 
        width: "auto",
        margin: "0px 24px 0px 24px",
        borderBottom: "1px solid #EAEAEA",
        ':last-child': {
          borderBottom: "none",
        },
        ':active': {
          backgroundColor: '#fff#'
        },
        ':focus': {
          backgroundColor: '#fff'
        },
        cursor: 'pointer',
        padding: "20px 0px 20px 0px",
      })
    }

    return (
      <div>
        <Select
          styles={customStyles}
          isSearchable={false}
          components={{ DropdownIndicator }}
          options={this.props.options}
          value={this.props.value}
          onChange={this.props.onChange}
          classNamePrefix="frg"
          onMenuOpen={() => this.setState({open: true})}
          onMenuClose={() => this.setState({open: false})}
          placeholder={this.props.placeholder}
        />
        <Modal show={this.state.open}/>
      </div>
    )
  }
}

export default ReportBuilderSelect;

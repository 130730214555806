import React, { Component } from "react";
import Highcharts from 'highcharts'
import HighchartsRegression from 'highcharts-regression'
import HighchartsReact from 'highcharts-react-official'
import { ButtonWhite, ButtonRed } from '@styles/buttons';

HighchartsRegression(Highcharts);

const EXAMPLE_DATA_1 = [
  [0, 1],
  [0, 1],
  [4, 2],
  [5, 2],
  [5.5, 2],
  [6, 2],
  [11.5, 3],            
  [11.8, 3],        
  [12, 3],
  [12.3, 3],                            
  [13, 3],        
  [18, 3],            
  [18, 4],        
  [22, 5],                                    
]

const EXAMPLE_DATA_2 = [
  [0, 1],
  [0, 1],
  [3, 2],
  [4.5, 2],
  [5.2, 2],
  [7, 2],
  [10.5, 3],            
  [11.3, 3],        
  [12.3, 3],
  [12.7, 3],                            
  [14, 3],        
  [17, 3],            
  [18, 4],        
  [20, 5],                                    
  [21, 5],                                    
  [26, 6],                                        
]

// This chart uses immutable without animations to allow the toggle on/off graph data 
// the problem is that the regression lines don't redraw, hence the whole graph is reinitalised instead.
// This is likely due to a bug in the highcharts regression package.
class Chart extends Component {

  constructor(props) {
    super(props)

    this.state = {
      receiving_lll: true,
      not_receiving_lll: true,
      options: this.options({receiving_lll: true, not_receiving_lll: true})
    }
  }

  options = (params) => {
    // default options
    var result = {
      title: {
        text: ''
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: 'scatter',
        animation: false,
        style: {
          fontFamily: "'Helvetica Neue', Helvetica, sans-serif"
        },
        height: 550,
      },  
      plotOptions: {
        series: {
            animation: false
        }
      },      
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        itemDistance: 40,
        margin: 40,
        title: {
          text: "",
        },
        itemStyle: {
          fontWeight: "500",
        }
      },  
      xAxis: {
        title: {
          enabled: true,
          text: "Months since first interview",
          style: {
            color: "#8A8A8A",
            fontWeight: "500"
          },
          margin: 50
        }, 
        gridLineWidth: 0,
        tickWidth: 0,
        lineColor: "#EBF1F2",
        labels: {
          style: {
            fontWeight: "500",
            color: "#1E1E1E"
          }
        }
      },
      yAxis: {
        title: {
          enabled: true,
          text: "Completed questionnaires",
          style: {
            color: "#8A8A8A",
            fontWeight: "500",
          },
          margin: 50
        },
        gridLineWidth: 0,
        labels: {
          style: {
            fontWeight: "500",
            color: "#1E1E1E"
          }
        }
      },  
      series: this.series(params),
    }

    // adjustments depending on the type of chart
    if (this.props.type == "connections") {
      result = {...result,
        yAxis: {
          title: {
            text: "Change in number of trusted connections",
            style: {
              color: "#8A8A8A",
              fontWeight: "500",
            },
            margin: 50
          },
          gridLineWidth: 0,
          labels: {
            formatter: function() {
              if (this.value == 0)
                return "Initial"
              else if (this.value > 0)
                return `+ ${this.value}`
              else
                return `- ${Math.abs(this.value)}`
            },
            style: {
              color: "#1E1E1E",
              fontWeight: "500"
            }
          },
        },        
      }
    } 

    return result

  }

  typeOptions() {

  }

  series = (params) => {
    let seriesData =  []
    if (params.receiving_lll) {
      seriesData.push({
        type: 'scatter',
        name: 'Completed Questionnaire',
        pointWidth: 1,
        color:  '#9360FF',
        data: this.props.lll_data || EXAMPLE_DATA_1,
        marker: {
          radius: 2
        },
        regression: true,
        regressionSettings: {
          name: "Average",
          // type: 'loess',
          type: 'linear',
          color:  '#9360FF',
          loessSmooth: 100,
        },      
      })
    }

    if (params.not_receiving_lll) {
      seriesData.push({
        type: 'scatter',
        name: 'Completed Questionnaire',
        pointWidth: 1,
        color:  'rgba(255,111,69,0.4)',
        data: this.props.non_lll_data || EXAMPLE_DATA_2,
        marker: {
          radius: 2
        },
        regression: true,
        regressionSettings: {
          name: "Average",
          // type: 'loess',
          type: 'linear',
          color:  'rgba(255,111,69,1)',
          loessSmooth: 100
        },      
      })
    }

    return seriesData;
  }

  setReceivingLLL = () => {
    if (!this.state.not_receiving_lll)
      return 

    this.setState({
      receiving_lll: !this.state.receiving_lll,
      options: this.options({
        receiving_lll: !this.state.receiving_lll,
        not_receiving_lll: this.state.not_receiving_lll
      })
    })
  }

  setNotReceivingLLL = () =>  {
    if (!this.state.receiving_lll)
      return 

    this.setState({
      not_receiving_lll: !this.state.not_receiving_lll,
      options: this.options({
        receiving_lll: this.state.receiving_lll,
        not_receiving_lll: !this.state.not_receiving_lll
      })
    })
  }

  render() {

    return (
      <section>
        <div style={{marginBottom: 30}}>
          <ButtonWhite active={this.state.receiving_lll} onClick={this.setReceivingLLL}>Receiving Lifelong Links</ButtonWhite>
          <ButtonRed style={{marginLeft: 30}} active={this.state.not_receiving_lll} onClick={this.setNotReceivingLLL}>Not receiving Lifelong Links</ButtonRed>
        </div>
        <div className="dashboard__chart-container">
          <HighchartsReact
            immutable={true}
            highcharts={Highcharts}
            options={this.state.options}
          />
        </div>
      </section>
    )
  }
}

export default Chart;

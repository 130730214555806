import React from 'react';
import {   
  Loader,
  LoadingMessage
} from './styles';
import Api from '@utils/api'

class RollKeysActions extends React.Component {
  state = {
    started: false,
  };

  rollkeys = () => {
    this.setState({started: true})
    Api.post(`/admin/rollkeys`)
      .then(response => {
        window.location = response.data.redirect_to 
      })
      .catch(error => {
        this.setState({started: false})
        console.error(error)
        window.alert("Sorry, something went wrong, please try again")
      })
  }

  render() {
    return (
      <div className="frg-form__actions">
        <div className="frg-form__actions__right">
          {!this.state.started && <a href="/user" className='button button--wide button--grey'>Cancel</a>}
          <div style={{display: "inline-flex", justifyContent: "flex-end", flexDirection: "column", alignItems: "flex-end"}}>
            <button className="button button--green" disabled={this.state.started} style={{marginRight: "0px"}} onClick={this.rollkeys}>Confirm to Roll Keys</button>
            {this.state.started && <LoadingMessage>
              <Loader /> Processing, this may take a few minutes, please don't leave this page
              </LoadingMessage>}
          </div>
        </div>
      </div>
    );
  }
}

export default RollKeysActions;
import _ from 'lodash'

export const DAYS = _.range(1,32).map(d => {return {label: d, value: d }})
export const MONTHS = [
  {label: "January", value: 1},
  {label: "February", value: 2},
  {label: "March", value: 3},
  {label: "April", value: 4},
  {label: "May", value: 5},
  {label: "June", value: 6},
  {label: "July", value: 7},
  {label: "August", value: 8},
  {label: "September", value: 9},
  {label: "October", value: 10},
  {label: "November", value: 11},
  {label: "December", value: 12},
]
export const YEARS = _.range((new Date()).getFullYear()+1, (new Date()).getFullYear()-30).map( y => { return { label: y, value: y }})

export const dateRange = (startDate, endDate) => {
  var monthNames = MONTHS.map(m => m.label)
  var startMonth = parseInt(startDate.getMonth());
  var endMonth   = parseInt(endDate.getMonth());
  var startYear  = parseInt(startDate.getFullYear());
  var endYear    = parseInt(endDate.getFullYear());
  var dates      = [];
  for (var i = startMonth; i >= 0; i--)
     dates.push(monthNames[i] + " " + startYear);
  startYear--;	
 for(var i = startYear; i > endYear; i--) {
 	 for (var j = monthNames.length-1; j >= 0; j--)
	 dates.push(monthNames[j] + " " + i);
 }

  for (var i = monthNames.length-1; i >= endMonth; i--)
	dates.push(monthNames[i] + " " + endYear);
  return dates;
}
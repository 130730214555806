import React, { Component } from 'react';
import { ButtonGreen } from '@styles/buttons';
import FRGSelect from './FRGSelect'
import { TextButton, DeleteButton } from '@styles/buttons';

const LLL_STATUSES = [
  {value: "receiving", label: "Receiving Lifelong Links"},
  {value: "not_receiving", label: "Not Receiving Lifelong Links"}
]

const option = (person) => ({
  value: person.id,
  label: `${person.circles_id} | ${person.child_id}`
})

class AssignPeople extends Component {

  constructor(props) {
    super(props)

    this.state = {
      people: props.people,
      assigned : props.assigned,
      selected : null,
    }
  }

  handleOnChange = (option) => {
    this.setState({selected: this.state.people.find(p => p.id === option.value)})
  }

  handleDelete = (index) => {
    this.setState({assigned: this.state.assigned.filter((p,i) => i !== index)})
  }

  handleAdd = (selected) => {
    this.setState({
      assigned: [...this.state.assigned, selected],
      selected: null,
    })
  }

  render() {
    const { people, assigned, selected } = this.state

    return (
      <div>
        <input name="people_ids" type="hidden" value={assigned.map(a => a.id).join(",")} />
        { assigned.length > 0 &&
          <table className="dashboard__table">
            <thead>
              <tr>
                <th className="col-30 align-left">Circles ID</th>
                <th className="col-30 align-left">Child ID</th>
                <th className="col-40 align-right"></th>
              </tr>
            </thead>
            <tbody>
              {assigned.map((person, index) => (
                <tr key={index}>
                  <td className="col-30 align-left">{person.circles_id}</td>
                  <td className="col-30 align-left">{person.circles_id}</td>
                  <td className="col-40 align-right">
                    <DeleteButton type="button" onClick={() => this.handleDelete(index)}></DeleteButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }

        <div className="assign-people-control">
          <FRGSelect
            options={people.filter(p => !assigned.find(a => a.id === p.id)).map(p => option(p))}
            onChange={option => this.handleOnChange(option)}
            value={selected ? option(selected): null}
          />
          <TextButton type="button" disabled={!selected} onClick={() => this.handleAdd(selected)} style={{ marginLeft: 28 }}>Add Young Person</TextButton>
        </div>
      </div>
    )
  }
}

export default AssignPeople;

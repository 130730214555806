import styled from 'styled-components';
import arrow from '@images/icons/arrows/arrow--left--green.svg';
import deleteIcon from '@images/icons/ui/icon--delete--grey.svg';

const colors = {
  // Brand
  FRGgreen: '#9360FF',
  FRGlightgreen: '#9360FF',
  FRGred: '#FF6F45',
  FRGblue: '#004EFF',
  FRGgrey: '#E9E9E9',
  FRGGrey2: '#E2E2E2',

  // RGBs
  FRGblueRGB: '0, 78, 255',
  // Base
  white: '#FFF',
  dark: '#3B3B3B',
  black: '#000',
  grey: '#767676',
  grey2: '#434b58'
}

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 15px;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 13px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 15px;
  line-height: normal;
  white-space: nowrap;
  transition: all .24s;
`

export const ButtonHistoryWhite = styled(Button)`
  background-color: ${colors.white};
  border-color: ${colors.white};
  color: ${colors.dark};
`
export const ButtonWhite = styled(Button)`
  background-color: ${colors.white};
  border-color: ${props => props.border ? colors.FRGGrey2 : colors.white};
  color: ${props => props.active ? colors.FRGgreen : colors.grey};
`
ButtonWhite.defaultProps = {
  active: true
}


export const ButtonHistoryGrey = styled(Button)`
  background-color: ${colors.FRGgrey};
  border-color: ${colors.FRGgrey};
  color: ${colors.grey};
`

export const ButtonGreen = styled(Button)`
  color: ${colors.FRGgreen};
  border-color: rgba(${colors.FRGlightgreen}, 0.5);
`

export const ButtonSolidGreen = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.FRGlightgreen};
  border-color: ${colors.FRGlightgreen};

  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`

export const ButtonSolidGreenConfirm = styled(Button)`
  color: ${colors.white};
  background-color: ${colors.FRGlightgreen};
  border-color: ${colors.FRGlightgreen};
  padding: 22px;
  width: 100%;
  text-align: center;
  border-radius: 7px; 
  justify-content: center;
  font-weight: 500;

  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`

export const ButtonBlack = styled(Button)`
  border-color: rgba(${colors.FRGlightgreen}, 0.5);
  color: ${colors.black};
`

export const ButtonGrey = styled(Button)`
  background-color: ${colors.FRGgrey};
  border-color: ${colors.FRGgrey};
  color: ${colors.black};
`

export const ButtonGreyTransparent = styled(Button)`
  background-color: transparent;
  border-color: ${colors.FRGGrey2};
  color: ${colors.grey2};
`

export const ButtonRedBG = styled(Button)`
  background-color: ${colors.FRGred};
  border-color: ${colors.FRGred};
  color: ${colors.white};
`

export const ButtonRed = styled(Button)`
  background-color: ${colors.white};
  border-color: ${colors.white};
  color: ${props => props.active ? colors.FRGred : colors.grey};

  :disabled {
    color: ${colors.grey};
  }
`
ButtonRed.defaultProps = {
  active: true
}

export const ButtonRedConfirm = styled(Button)`
  background-color: #FF7A7A;
  border-radius: 7px;
  padding: 20px 64px;
  border: 1px solid #FF7A7A;
  font-weight: 500;
  color: ${colors.white};
`

export const ButtonBlue = styled(Button)`
  border-color: rgba(${colors.FRGblueRGB}, 0.17);
  color: ${colors.FRGblue};

  &:hover {
    background-color: ${colors.FRGblue};
    border-color: rgba(${colors.FRGblueRGB}, 1);
    color: ${colors.white};
  }

  ${({ active }) => active && `
    border-color: ${colors.FRGblue};
    background-color: ${colors.FRGblue};
    color: ${colors.white};
  `}
`

export const ButtonSolidBlue = styled(Button)`
  border-color: ${colors.FRGblue};
  background-color: ${colors.FRGblue};
  color: ${colors.white};
`

export const BackButton = styled(Button)`
  background-color: ${colors.white};
  border-color: ${colors.white};
  color: ${colors.FRGlightgreen};
  background-image: url(${arrow});
  background-position: left 10px center;
  background-repeat: no-repeat;
  padding: 7px 15px 7px 28px;
`

export const TextButton = styled.button`
  font-size: 13px;
  font-weight: 500;
  color: #9360FF;
`

export const EditButton = styled.button`
  border: 0;
  opacity: 0.5;
  font-weight: 500;
  font-size: 13px;
  color: #004EFF;
  align-self: center;
`

export const DeleteButton = styled.button`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(${deleteIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
`

export const ContinueButton = styled(Button)`
  display: inline-block;
  cursor: pointer;
  padding: 7px 15px;
  border-radius: 15px;
  font-size: 13px;
  text-align: center;
  line-height: normal;
  background-color: ${colors.FRGblue};
  color: ${colors.white};
`

// ToggleSwitch styles

export const ToggleSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`

export const ToggleSwitchOption = styled.div`
  p {
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.off ? "#999" : "#000"};
    text-align: left;
    line-height: 21px;
    transition: color 0.3s ease-in-out;
  }
`

export const ToggleSwitchSlider = styled.div`
  box-sizing: border-box;
  margin: 0 6px;
  width: 32px;
  height: 16px;
  padding: 3px;
  background: #9360FF;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ToggleSwitchSliderThumb = styled.span`
  display: inline-block;
  background: #fafafa;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  transform: ${props => props.toggled ? 'translateX(14px)' : 'translateX(0)'};
  transition: transform 0.3s ease-in-out;
`

export const ButtonOption = styled(Button)`
  padding: 16px 37px;
  border-radius: 6px;
  background-color: ${props => props.active ? '#2E2E2E' : '#F7F8F9'};
  border-color: ${props => props.active ? '#2E2E2E' : '#E1E1E2'};
  color: ${props => props.active ? '#FFFFFF' : '#454545'};
`
export const ButtonLargeSolidGreen = styled(Button)`
  padding: 18px 42px;
  border-radius: 25px;
  color: ${colors.white};
  background-color: ${colors.FRGlightgreen};
  border-color: ${colors.FRGlightgreen};
`
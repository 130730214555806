import React, { Component } from "react";
import FRGSelect from '@components/FRGSelect'

class DashboardQuestions extends Component {

  constructor(props) {
    super(props)

    this.state = {
      questionnaire_template_id: props.questionnaire_templates[0].id
    }
  }

  render() {
    let options = this.props.questionnaire_templates.map(q => ({value: q.id, label: q.internal_name}))

    return (
      <div className="module__dashboard-questions">
        <div className="dashboard-questions__select">
          <FRGSelect
            options={options}
            value={options.find(o => o.value === this.state.questionnaire_template_id)}
            onChange={o => this.setState({questionnaire_template_id: o.value})}
            noBG
            sizeLarge
            maxWidth={120}
          />
        </div>
        <div className="dashboard-questions__question-list">
          <i className="dashboard-questions__icon icon icon--chart"></i>
          <p className="dashboard-questions__title">Statistics for Individual Questions</p>
          <ul>
            {this.props.questions.filter(q => q.questionnaire_template_id === this.state.questionnaire_template_id).map(q => 
              <li key={q.id}><a href={`/frg/statistics/question/${q.id}`} dangerouslySetInnerHTML={{ __html: q.content }}></a></li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}

export default DashboardQuestions;
